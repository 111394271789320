export const depositMoney = (amount,thread,indexNo) => {
    return (dispatch) => {
        dispatch({
            type: 'deposit',
            payload: amount,
            thread: thread,
            indexNo: indexNo
        })
    }
}

export const editChat = (indexNo,thread,chatIndex,chatArray) => {
    return (dispatch) => {
        dispatch({
            type: 'editChat',
            payload: chatArray,
            thread: thread,
            indexNo: indexNo,
            chatIndex: chatIndex
        })
    }
}

export const deleteThread = (indexNo) => {
    return (dispatch) => {
        dispatch({
            type: 'deleteThread',
            payload: indexNo,
        })
    }
}

export const pushAnswer = (indexNo,thread,chatIndex,answer) => {
    return (dispatch) => {
        dispatch({
            type: 'pushAnswer',
            indexNo: indexNo,
            thread: thread,
            chatIndex: chatIndex,
            payload: answer,
        })
    }
}

export const pushQuestion = (indexNo,thread,chatIndex,question) => {
    return (dispatch) => {
        dispatch({
            type: 'pushQuestion',
            indexNo: indexNo,
            thread: thread,
            chatIndex: chatIndex,
            question: question,
        })
    }
}

export const pushReRes = (indexNo,thread,chatIndex,answer,saved) => {
    return (dispatch) => {
        dispatch({
            type: 'pushReRes',
            indexNo: indexNo,
            thread: thread,
            chatIndex: chatIndex,
            answer: answer,
            saved: saved,
        })
    }
}

export const withdrawMoney = (amount) => {
    return (dispatch) => {
        dispatch({
            type: 'withdraw',
            payload: amount
        })
    }
}

export const resetStore = () => ({
    type: 'clearStore'
  });