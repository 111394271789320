import React from "react";
import { ReactDOM } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../state/index';
import { Button } from "react-bootstrap";



const ActionButtons = () => {
    const dispatch = useDispatch();
    const actions = bindActionCreators(actionCreators, dispatch)
    return (
        <div className="actionButtons">
            
        </div>
    );
}
export default ActionButtons