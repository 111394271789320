import axios from "axios";
import { Configuration, OpenAI, OpenAIApi } from "openai";
import xtype from "xtypejs";

async function getApiKey() {
  const env = process.env;
  const formData = {
    name: "apikey"
  };
  try {
    const res = await axios.post(env.REACT_APP_API_URL + "get_setting.php", formData);
    return res.data;
  } catch (error) {
    console.error(`Error getting API key: ${error.message}`);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

let api_key = await getApiKey();

const configuration = new Configuration({
  apiKey: api_key,
  dangerouslyAllowBrowser: true
});
const openai = new OpenAIApi(configuration);


export const callApi = async (req, callback) => {
  const prompt = req.body.prompt || '';
  const model = req.body.model || '';
  const temp = req.body.temp;
  const maxLength = req.body.maxLength;
  const topP = req.body.topP;
  const frequencyPenalty = req.body.frequencyPenalty;
  const presencePenalty = req.body.presencePenalty;
  const bestOf = req.body.bestOf;
  var stopSequence;

  if (xtype(req.body.stopSequence) == "empty_array") {
    stopSequence = "";
  } else {
    stopSequence = req.body.stopSequence;
  }

  if (prompt.trim().length === 0) {
    // res.status(400).json({
    //   error: {
    //     message: "Please enter a valid prompt",
    //   }
    // });
    console.log("Please enter a valid prompt");

  }

  try {
    const completion = await openai.createCompletion({
      model: model,
      prompt: prompt,
      temperature: temp,
      max_tokens: maxLength,
      top_p: topP,
      best_of: bestOf,
      frequency_penalty: frequencyPenalty,
      presence_penalty: presencePenalty,
      stop: stopSequence,
    });
    console.log(completion
      );
    return callback(completion.data.choices[0].text);
  } catch (error) {
    // Consider adjusting the error handling logic for your use case
    if (error.response) {
      console.error(error.response.status, error.response.data);
      return callback(error.response.data);
      //  res.status(error.response.status).json(error.response.data);
    } else {
      console.error(`Error with OpenAI API request: ${error.message}`);
      // res.status(500).json({
      //   error: {
      //     message: 'An error occurred during your request.',
      //   }
      // });
    }
  }
}

