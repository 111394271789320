import React, { useState } from 'react'
import { Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDOM from "react-dom";
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';


const Menu = props => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {props.children}
    </components.Menu>
  );
};
const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Enter a Sequence</span> 
    </components.NoOptionsMessage>
  );
};


function RightSidebar({
                      modelDropdown,
                      setmodelDropdown,
                      tempRange,
                      settempRange,
                      maxLengthRange,
                      setmaxLengthRange,
                      topP,
                      setTopP,
                      frequencyPenalty,
                      setFrequencyPenalty,
                      presencePenalty,
                      setPresencePenalty,
                      bestOf,
                      setBestOf,
                      ist,
                      setIst,
                      irt,
                      setIrt,
                      handleStopSequence}){

    const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0;

    return(
        <div className='rightSidebar'>
            <div className='modelDropdownDiv'>
              <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="modelTooltip">
                          The model which will generate the completion. Some models
                          are suitable for natural language tasks, others specialize 
                          in code.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150}  >
                    <h5>Model</h5>
              </OverlayTrigger>
                <Dropdown className='modelDropdown'>
                  <Dropdown.Toggle className='modelDropdownToggle' id="dropdown-basic">
                    {modelDropdown}
                  </Dropdown.Toggle>
            
                  <Dropdown.Menu className='dropdownMenu'>
                    <h6>Chat GPT</h6>
                    <Dropdown.Item className='dropdownItem' name={"text-davinci-003"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-davinci-003</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"text-curie-001"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-curie-001</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"text-babbage-001"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-babbage-001</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"text-ada-001"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-ada-001</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"text-davinci-002"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-davinci-002</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"text-davinci-001"} onClick={ (e) => setmodelDropdown(e.target.name)}>text-davinci-001</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"davinci-instruct-beta"} onClick={ (e) => setmodelDropdown(e.target.name)}>davinci-instruct-beta</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"davinci"} onClick={ (e) => setmodelDropdown(e.target.name)}>davinci</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"curie-instruct-beta"} onClick={ (e) => setmodelDropdown(e.target.name)}>curie-instruct-beta</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"curie"} onClick={ (e) => setmodelDropdown(e.target.name)}>curie</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"babbage"} onClick={ (e) => setmodelDropdown(e.target.name)}>babbage</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"ada"} onClick={ (e) => setmodelDropdown(e.target.name)}>ada</Dropdown.Item>
                    <h6>Codex</h6>
                    <Dropdown.Item className='dropdownItem' name={"code-davinci-002"} onClick={ (e) => setmodelDropdown(e.target.name)}>code-davinci-002</Dropdown.Item>
                    <Dropdown.Item className='dropdownItem' name={"code-cushman-001"} onClick={ (e) => setmodelDropdown(e.target.name)}>code-cushman-001</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='tempRangeDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="tempRangeTooltip">
                          Controls randomness : Lowering results in less random completions. As the Temperature
                          approaches zero, the model will become deterministic and repetitive.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='tempRangeLabel'>
                    Temperature 
                    <p className='viewTempRange'>{tempRange}</p>
                </Form.Label>
            </OverlayTrigger>    
                <Form.Range className='tempRange' value={tempRange} min={0} max={1} step={0.01} onChange={ (e) => settempRange(parseFloat(e.target.value)) } />
            </div>
            <div className='maxLengthRangeDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="maxLengthTooltip">
                          The maximum number of tokens to generate.Requests can use up to 2,048 or 4000 tokens
                          shared between prompt and completion. The exact limit varies by model. (One token is roughly 4
                          characters for normal English text)
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='maxLengthRangeLabel'>
                    Maximum length 
                    <p className='viewmaxLengthRange'>{maxLengthRange}</p>
                </Form.Label>
            </OverlayTrigger>
                <Form.Range className='maxLengthRange'value={maxLengthRange} min={1} max={4000} step={1} onChange={ (e) => setmaxLengthRange(parseFloat(e.target.value)) } />
            </div>
            <div className='stopSequenceDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="stopSequenceTooltip">
                          Up to four Sequences where the API will stop generating further tokens. The returned text will not
                          contain the stop sequence.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <h5 className='stopSequenceLabel'>Stop Sequences</h5>
            </OverlayTrigger>
                <h5 className='stopSequenceLabel2'>Enter sequence and press Tab</h5>
              <CreatableSelect
                components={{ Menu,
                    DropdownIndicator:() => null,
                    IndicatorSeparator:() => null,
                    NoOptionsMessage  }}
                styles={{
                  option: (provided, state) => {
                    if (state.data.__isNew__) {
                      return { ...provided, color: "black", backgroundColor:"white" };
                    } else {
                      return provided;
                    }
                  },
                }}
                formatCreateLabel={userInput => `Add "${userInput}"`}
                isMulti
                isClearable
                isValidNewOption={isValidNewOption}
                placeholder={""}
                onChange={handleStopSequence}
              />
            </div>
            <div className='topPDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="topPTooltip">
                          Controls diversity via nucleus sampling: 0.5 means half of all 
                          likelihood-weighted options are considered.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='topPLabel'>
                    Top P 
                    <p className='viewtopP'>{topP}</p>
                </Form.Label>
            </OverlayTrigger>
                <Form.Range className='topP'value={topP} min={0} max={1} step={0.01} onChange={ (e) => setTopP(parseFloat(e.target.value)) } />
            </div>
            <div className='frequencyPenaltyDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="frequencyPenaltyTooltip">
                          How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's
                          likelihood to repeat the same line verbatim.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='frequencyPenaltyLabel'>
                    Frequency penalty
                    <p className='viewfrequencyPenalty'>{frequencyPenalty}</p>
                </Form.Label>
            </OverlayTrigger>
                <Form.Range className='frequencyPenalty'value={frequencyPenalty} min={0} max={2} step={0.01} onChange={ (e) => setFrequencyPenalty(parseFloat(e.target.value)) } />
            </div>
            <div className='presencePenaltyDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="presencePenaltyTooltip">
                          How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about 
                          new topics.
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='presencePenaltyLabel'>
                    Presence penalty
                    <p className='viewpresencePenalty'>{presencePenalty}</p>
                </Form.Label>
            </OverlayTrigger>
                <Form.Range className='presencePenalty'value={presencePenalty} min={0} max={2} step={0.01} onChange={ (e) => setPresencePenalty(parseFloat(e.target.value)) } />
            </div>
            <div className='bestOfDiv'>
            <OverlayTrigger 
              placement='left' 
              overlay={ <Tooltip id="bestOfTooltip">
                          Generates multiple completions server-side, and displays only the best.
                          Streaming only works when set to 1. Since it acts as a multiplier on the number of completions, this parameters can eat into 
                          your token quota very quickly -use caution!
                        </Tooltip>} 
              delayShow={300}
              delayHide={150} >
                <Form.Label className='bestOfLabel'>
                    Best of
                    <p className='viewbestOf'>{bestOf}</p>
                </Form.Label>
            </OverlayTrigger>
                <Form.Range className='bestOf' value={bestOf} min={1} max={20} step={1} onChange={ (e) => setBestOf(parseFloat(e.target.value)) } />
            </div>
            <div className='istDiv'>
              <OverlayTrigger 
                placement='left' 
                overlay={ <Tooltip id="istTooltip">
                            Text to append after the user's input to format the model for a response.
                          </Tooltip>} 
                delayShow={300}
                delayHide={150}
 >
                  <p className='istLabel'>Inject start text</p>
              </OverlayTrigger>
              <input
                type="text"
                name="animal"
                className="itInput"
                value={ist}
                autoComplete={"off"} 
                onChange={(e) => setIst(e.target.value)}
              />
            </div>
            <div className='irtDiv'>
              <OverlayTrigger 
                placement='left' 
                overlay={ <Tooltip id="irtTooltip">
                            Text to append after the model's generation to continue 
                            the patterned structure.
                          </Tooltip>} 
                delayShow={300}
                delayHide={150}>
                  <p className='irtLabel'>Inject restart text</p>
              </OverlayTrigger>
              <input
                type="text"
                name="animal"
                className="itInput"
                value={irt}
                autoComplete={"off"} 
                onChange={(e) => setIrt(e.target.value)}
              />
            </div>
        </div> 
    )
}
export default RightSidebar;