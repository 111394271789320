import React from 'react'

function NewUser(){
    return(
        <div className="freeDiv">
            <h1>Chat Bot</h1>
            <h3>Chatting made easy with our AI-powered chatbot!</h3>
        </div>
    )
}
export default NewUser;