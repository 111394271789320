import React from 'react'

function InputForm({onSubmit,animalInput,setAnimalInput,hideReRes,hideStopRes,stopGenerating,regenerateRes}){
    // console.log(showStopRes);
    return(
        <>
        <div className='resDiv'>
            <button className='btn' hidden={hideReRes} onClick={ () => regenerateRes() } >
                <i className="fa-solid fa-rotate-right p-1"></i>
                Regenerate Response
            </button>
            <button className='btn' hidden={hideStopRes} onClick={ () => stopGenerating() } >
                <i className="fa-solid fa-stop p-1"></i>
                Stop Generating
            </button>
        </div>
        <div className="inputAndButtonDiv">
            <form onSubmit={onSubmit} >
                <div className="inputDiv">
                    <input
                      type="text"
                      name="animal"
                      className="mainInput"
                      value={animalInput}
                      autoComplete={"off"} 
                      onChange={(e) => setAnimalInput(e.target.value)}
                    />
                </div>
                <div className="buttonDiv">
                    <button type='submit' className="inputButton">
                      <i className="fa fa-paper-plane"></i>
                    </button>
                </div> 

            </form>
        </div>
        </>
    )
}
export default InputForm;