import xtype from 'xtypejs';
const initialState = {
    conversations: [
        
    ]
};
var indexNo;
const amountreducer = (state=initialState, action) => {
    if(action.type==='deposit'){ 
        //to get index no of thread in object
        // for (let i = 0; i < state.conversations.length; i++) {
        //     if(state.conversations[i].hasOwnProperty(action.thread)){
        //         indexNo = i;
        //     }else{
                
        //     }
        // }
        //commands to what to do
        // if(typeof indexNo ==='undefined'){
        //     // state.conversations.push(action.payload);
        //     return{
        //         ...state,
        //         conversations: [...state.conversations, action.payload]
        //     }
        // }else{
        //     console.log(indexNo);
        //     state.conversations[indexNo] = action.payload;
        //     return{
        //         ...state
        //     }
        // }
        // console.log(action.indexNo);
        if(state.conversations[action.indexNo]){
            state.conversations[indexNo] = action.payload;
            return{
                ...state
            }
        }else{
            return{
                ...state,
                conversations: [...state.conversations, action.payload]
            }
        }
    }    
    else if(action.type==='deleteThread'){
        state.conversations.splice(action.payload,1);
        return{
            ...state
        }

    }
    else if(action.type==='editChat'){
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].question = action.payload.question;
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].answer = action.payload.answer;
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].saved = action.payload.saved;
        return{
            ...state
        }

    }else if(action.type==='pushAnswer'){
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].answer = action.payload;
        return{
            ...state
        }

    }else if(action.type==='pushQuestion'){
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].question = action.question;
        return{
            ...state
        }

    }
    else if(action.type==='pushReRes'){
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].answer = action.answer;
        state.conversations[action.indexNo][action.thread].chat[action.chatIndex].saved = action.saved;
        return{
            ...state
        }

    }
    else if (action.type==='clearStore'){
        state = initialState;
        return{
            ...state 
        }
    }   
    else if (action.type==='withdraw'){
        return state - action.payload
    }
    else {
        return state;
    }

} 
export default amountreducer;