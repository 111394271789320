import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import '../App.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function LoginForm() {
    const navigate = useNavigate();
    const name = "admin";
    const password = "S-EImh}FUYus";
    const env = process.env;
    const [username, setusername] = useState("");
    const [userPass, setUserPass] = useState("");
    const handleLoginSubmit = async(e) => {
        e.preventDefault();

        const formData = {
            username: username,
            password: userPass
        }

        if (username !== "" && userPass !== "") {
            await axios.post(env.REACT_APP_API_URL+"login.php",formData)
            .then((res) => {
                if(res.data == "DONE")
                {
                    localStorage.setItem("login","admin");
                    window.location.reload();
                }else{
                    toast.error("Invalid Username/Password");
                }
            })
            .catch(function(err){
                console.log(err);
            })
        } else {
            toast.error("Please Fill Required Fields!");
        }
    }
    
    return (
        <div className='login_container'>
            <ToastContainer
                pauseOnFocusLoss
                pauseOnHover

            />
            <div className='global-container'>
                <div className='card login_card' style={{}}>
                    <div className='card-header login-header'>
                        <h2 className='text-center mb-0 mt-2'>Login</h2>
                    </div>
                    <div className='card-body'>
                        <form id="auth_form" className='mt-1' onSubmit={handleLoginSubmit}>
                            <div className="mb-3">
                                <label>Username</label>
                                <input
                                    type="text"
                                    className="form-control login_input"
                                    placeholder="Enter Username"
                                    value={username}
                                    onChange={(e) => { setusername(e.target.value) }}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control login_input"
                                    placeholder="Enter password"
                                    value={userPass}
                                    onChange={(e) => { setUserPass(e.target.value) }}
                                />
                            </div>
                            <div className="d-grid text-center mb-3">
                                <button type="submit" className="btn auth_btn login_button m-0">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;