import React, { useEffect } from 'react'
import { useState } from "react";
import 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { callApi } from "../../api/generate";
import xtype from 'xtypejs';
import { useParams } from 'react-router-dom';


var conversations={};
var result;
function OldInputForm({ ist,irt,modelDropdown,tempRange,maxLengthRange,stopSequence,topP,frequencyPenalty,presencePenalty,bestOf,setIsExist,setQueInput,
  hideStopRes,setHideStopRes,hideReRes,setHideReRes,setFullText,editAnsExist,blinkerId,chatId,setChatId,text,fullText,
  setChatIndex,setblinkerId })
  {
    const [animalInput, setAnimalInput] = useState("");
    const dispatch = useDispatch();
    const actions = bindActionCreators(actionCreators, dispatch);
    const amount = useSelector(state => state.amount);
    var mainprompt="";
    var indexNo = 0;
    let params = useParams();
    const token = params.token;

    for (let i = 0; i < amount.conversations.length; i++) {
        if(amount.conversations[i].hasOwnProperty(token)){
            indexNo = i;
        }
    }
      
    if(amount.conversations[indexNo]){
      amount.conversations[indexNo][token].chat.map(function (chat,index){
        mainprompt = mainprompt + "\n\n" + chat.question;
        mainprompt = mainprompt + "\n" + chat.answer;
      });
      conversations = amount.conversations[indexNo];
    }  
    
      async function onSubmit(event) {
          event.preventDefault();
          setIsExist(true);
          setQueInput(animalInput);
          setAnimalInput("");
          try {
            mainprompt = mainprompt + "\n\n" + animalInput + ist;
            //fetching response from openai api
            var req={body:{
              prompt:mainprompt,
              model:modelDropdown,
              temp:tempRange,
              maxLength:maxLengthRange,
              stopSequence:stopSequence,
              topP:topP,
              frequencyPenalty:frequencyPenalty,
              presencePenalty:presencePenalty,
              bestOf:bestOf
            }
          };
          await callApi(req,function(res){
            if(res.error){
              alert(res.error.message);
            }else{
              mainprompt = mainprompt + res + irt + "\n\n";
              result = res;
              result = result.substr(2);
  
              var yourString = animalInput; 
              var maxLength = 20 
              var title = yourString.substr(0, maxLength);

              var chats = {
                title: title,
                chat: [],
              }
              var conversation={
                question:animalInput + ist,
                answer:null,
                saved: Date.now(),
              };
              
              if(conversations.hasOwnProperty(token)){
                conversations[token].chat.push(conversation);
              }else{
                conversations[token]=chats;
                conversations[token].chat.push(conversation);
              }
              actions.depositMoney(conversations,token,indexNo);
              if(result == ""){
                setFullText("." + irt);
              }else{
                setFullText(result + irt);
              }
              setChatIndex(0);
              setHideReRes(true);
              setHideStopRes(false);
            }
            setIsExist(false);
          });
          } catch(error) {
            // Consider implementing your own error handling logic here
            console.error(error);
            alert(error.message);
          }
          setQueInput("");
        }

        async function regenerateRes(){
          var chatIndex = amount.conversations[indexNo][token].chat.length - 1;
          setblinkerId(chatIndex);
          setChatId(chatIndex);
          var que = amount.conversations[indexNo][token].chat[chatIndex].question;
          var emptyResult = null;
          actions.pushAnswer(indexNo,token,chatIndex,emptyResult);
          var req={body:
            {
              prompt:que,
              model:modelDropdown,
              temp:tempRange,
              maxLength:maxLengthRange,
              stopSequence:stopSequence,
              topP:topP,
              frequencyPenalty:frequencyPenalty,
              presencePenalty:presencePenalty,
              bestOf:bestOf
            }
          };

          await callApi(req,function(res){
            if(res.error){
              alert(res.error.message);
            }else{
              result = res;
              result = result.substr(2);
              if(result){
                setFullText(result + irt);
              }else{
                setFullText("." + irt);
              }
              setChatIndex(0);
              setblinkerId(false);
              setHideReRes(true);
              setHideStopRes(false);
            }
          });
        }
        function stopGenerating(){
          setChatIndex(fullText.length);
        }
    return(
        <>
          <div className='resDiv'>
              <button className='btn' onClick={ () => regenerateRes() } hidden={hideReRes}>
                  <i className="fa-solid fa-rotate-right p-1"></i>
                  Regenerate Response
              </button>
              <button className='btn' onClick={ () => stopGenerating() } hidden={hideStopRes} >
                  <i className="fa-solid fa-stop p-1"></i>
                  Stop Generating
              </button>
          </div>
          <div className="inputAndButtonDiv">
              <form onSubmit={onSubmit} >
                  <div className="inputDiv">
                      <input
                        type="text"
                        name="animal"
                        className="mainInput"
                        value={animalInput}
                        autoComplete={"off"} 
                        onChange={(e) => setAnimalInput(e.target.value)}
                      />
                  </div>
                  <div className="buttonDiv">
                      <button type='submit' className="inputButton">
                        <i className="fa fa-paper-plane"></i>
                      </button>
                  </div>  
              </form>
          </div>
        </>
    )
}
export default OldInputForm;