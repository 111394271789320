import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import OldChats from './OldChats';
import OldInputForm from './OldInputForm';

var newChat = false;

function MainContainer({ ist,irt,modelDropdown,tempRange,maxLengthRange,stopSequence,topP,frequencyPenalty,presencePenalty,bestOf,isExist,setIsExist,queInput,setQueInput,
    editQue,setEditQue })
{
    const [hideStopRes,setHideStopRes] = useState(true);
    const [hideReRes,setHideReRes] = useState(false);
    const [blinkerId,setblinkerId] = useState(false);
    const [text, setText] = useState("");
    const [fullText, setFullText] = useState("");
    const [chatIndex, setChatIndex] = useState(0);
    const [editAnsStatus,setEditAnsStatus] = useState(false);
    const [editAnsExist,setEditAnsExist] = useState(false);
    const [editId,setEditId] = useState("");
    const [editExist,setEditExist] = useState(false);
    const [editAnsId,setEditAnsId] = useState("");
    const [chatId,setChatId] = useState(0);
    
    var indexNo = 0;
    let params = useParams();
    const token = params.token;
    const dispatch = useDispatch();
    const actions = bindActionCreators(actionCreators, dispatch);
    const amount = useSelector(state => state.amount);
    for (let i = 0; i < amount.conversations.length; i++) {
        if(amount.conversations[i].hasOwnProperty(token)){
            indexNo = i;
        }
    }

    useEffect(() => {
        if(fullText.length !== 0){
          if (chatIndex < fullText.length) {
            setTimeout(() => {
              setText(text + fullText[chatIndex])
              setChatIndex(chatIndex + 1)
            }, 1)
          }else{
            // for (let i = 0; i < amount.conversations.length; i++) {
            //   if(amount.conversations[i].hasOwnProperty(token)){
            //       indexNo = i;
            //   }
            // }  
  
            if(editAnsStatus === false){
              if(amount.conversations[indexNo][token]){
                setChatId(amount.conversations[indexNo][token].chat.length -1);
              }else{
                setChatId(0);
              }
            }
            console.log(amount.conversations[indexNo][token].chat[chatId]);
            console.log(indexNo,token,chatId);
            actions.pushReRes(indexNo,token,chatId,text+irt,Date.now());
            setText("");
            setFullText("");
            setHideStopRes(true);
            setHideReRes(false);
            setChatId(amount.conversations[indexNo][token].chat.length);
            setEditAnsStatus(false);
          }
        }
    }, [chatIndex,fullText])
    
    // console.log(queInput);
    return(
        <div className="mainContainer">
            <div className="chatContainer">
                {
                    <OldChats isExist={isExist} 
                    queInput={queInput} 
                    editQue={editQue} 
                    setEditQue={setEditQue} 
                    ist={ist} 
                    irt={irt} 
                    modelDropdown={modelDropdown}
                    tempRange={tempRange}
                    maxLengthRange={maxLengthRange}
                    stopSequence={stopSequence}
                    topP={topP}
                    frequencyPenalty={frequencyPenalty}
                    presencePenalty={presencePenalty}
                    bestOf={bestOf}
                    setIsExist={setIsExist}
                    hideStopRes={hideStopRes}
                    setHideStopRes={setHideStopRes}
                    hideReRes={hideReRes}
                    setHideReRes={setHideReRes}
                    fullText={fullText}
                    editAnsExist={editAnsExist} 
                    blinkerId={blinkerId} 
                    chatId={chatId}
                    setChatId={setChatId}
                    text={text}
                    setEditId={setEditId}
                    setEditAnsStatus={setEditAnsStatus}
                    setblinkerId={setblinkerId}
                    indexNo={indexNo}
                    token={token}
                    setEditExist={setEditExist}
                    setFullText={setFullText}
                    amount={amount}
                    editExist={editExist}
                    editId={editId}
                    setChatIndex={setChatIndex}
                    setEditAnsId={setEditAnsId}
                    />
                }
            </div>
            <OldInputForm 
                        ist={ist} 
                        irt={irt} 
                        modelDropdown={modelDropdown}
                        tempRange={tempRange}
                        maxLengthRange={maxLengthRange}
                        stopSequence={stopSequence}
                        topP={topP}
                        frequencyPenalty={frequencyPenalty}
                        presencePenalty={presencePenalty}
                        bestOf={bestOf}
                        setIsExist={setIsExist}
                        setQueInput={setQueInput}
                        hideStopRes={hideStopRes}
                        setHideStopRes={setHideStopRes}
                        hideReRes={hideReRes}
                        setHideReRes={setHideReRes} 
                        fullText={fullText}
                        editAnsExist={editAnsExist} 
                        blinkerId={blinkerId} 
                        chatId={chatId}
                        setChatId={setChatId}
                        text={text}
                        setFullText={setFullText}
                        setChatIndex={setChatIndex}
                        setblinkerId={setblinkerId}
                        />
        </div>
    )
}

export default MainContainer;