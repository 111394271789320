import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import axios from 'axios';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { BallTriangle } from 'react-loader-spinner';

function NewUser() {
    const navigate = useNavigate();
    const env = process.env;
    const [loading, setloading] = useState(true);
    const [username, setusername] = useState("");
    const [userPass, setUserPass] = useState("");
    const [apikey, setapikey] = useState("");

    useEffect(() => {
        (async () => {
            await axios.get(env.REACT_APP_API_URL + "fetch_settings.php")
            .then((res) => {
                var st = res.data;
                setusername(st.username);
                setapikey(st.apikey);
                setloading(false);
            })
            .catch(function (err) {
                console.log(err);
            })
        })();
      
        return () => {
          // this now gets called when the component unmounts
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            username: username,
            password: userPass,
            apikey: apikey
        }

        if (username !== "" && apikey !== "") {
            await axios.post(env.REACT_APP_API_URL + "update_settings.php", formData)
                .then((res) => {
                    // console.log(res);
                    if (res.data == "DONE") {
                        navigate("/");
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
        }
    }
    return (
        <>
            <div className="global-container">
                {(loading) ?
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#75767c"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                    :
                    <Card bg='dark' className='text-white settings_card p-3'>
                        <Card.Body>
                            <h3>Settings</h3>
                            <Form onSubmit={(e) => handleSubmit(e)}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className='settings_label'>Username</Form.Label>
                                            <Form.Control type="text" required value={username} onChange={(e) => { setusername(e.target.value) }} placeholder="Enter Username" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label className='settings_label'>Password</Form.Label>
                                            <Form.Control type="password" autoComplete='off' value={userPass} onChange={(e) => { setUserPass(e.target.value) }} placeholder="Enter Password" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label className='settings_label'>API Key</Form.Label>
                                            <Form.Control type="text" required value={apikey} onChange={(e) => { setapikey(e.target.value) }} placeholder="API Key" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                }
            </div>
        </>
    )
}
export default NewUser;