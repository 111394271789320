import React, { useEffect, useState } from 'react'
import 'react-bootstrap';
import ActionButtons from '../ActionButtons';
import MainContainer from './MainContainer';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';


var stopSequence = [];

function OldChatContainer(){
    const [modelDropdown,setmodelDropdown] = useState("text-davinci-003");
    const [tempRange,settempRange] = useState(0);
    const [maxLengthRange,setmaxLengthRange] = useState(4000);
    const [topP,setTopP] = useState(0);
    const [frequencyPenalty,setFrequencyPenalty] = useState(0);
    const [presencePenalty,setPresencePenalty] = useState(0);
    const [bestOf,setBestOf] = useState(1);
    const [ist,setIst] = useState("");
    const [irt,setIrt] = useState("");
    const [isExist,setIsExist] = useState(false);
    const [queInput, setQueInput] = useState("");
    const [editQue, setEditQue] = useState("");
    const [watchNewChat,setwatchNewChat] = useState(false);


    function handleStopSequence(inputValue){
      stopSequence = [];
      inputValue.map(function(item){
        stopSequence.push(item.value);
      })
    }

    return(
        <div className='body' id='body'>
            <LeftSidebar setwatchNewChat={setwatchNewChat} />
            <ActionButtons />
            <MainContainer 
              ist={ist} 
              irt={irt} 
              modelDropdown={modelDropdown}
              tempRange={tempRange}
              maxLengthRange={maxLengthRange}
              stopSequence={stopSequence}
              topP={topP}
              frequencyPenalty={frequencyPenalty}
              presencePenalty={presencePenalty}
              bestOf={bestOf}
              isExist={isExist}
              setIsExist={setIsExist}
              queInput={queInput}
              setQueInput={setQueInput}
              editQue={editQue}
              setEditQue={setEditQue}  />
            <RightSidebar 
              modelDropdown={modelDropdown}
              setmodelDropdown={setmodelDropdown}
              tempRange={tempRange}
              settempRange={settempRange}
              maxLengthRange={maxLengthRange}
              setmaxLengthRange={setmaxLengthRange}
              stopSequence={stopSequence}
              topP={topP}
              setTopP={setTopP}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
              bestOf={bestOf}
              setBestOf={setBestOf}
              irt={irt}
              setIrt={setIrt}
              ist={ist}
              setIst={setIst} 
              handleStopSequence={handleStopSequence} />
        </div>
    )
}
export default OldChatContainer;